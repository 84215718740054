import SettingsGeneralBeta from "pages/Settings/SettingsGeneralBeta";
import React from "react";

const AccountTab = () => {
  return (
    <div className="p-5">
      <SettingsGeneralBeta />
    </div>
  );
};

export default AccountTab;
