import React from "react";

const ShowPasswordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M9 11.5356C9.59674 11.5356 10.169 11.2986 10.591 10.8766C11.013 10.4547 11.25 9.88238 11.25 9.28564C11.25 8.68891 11.013 8.11661 10.591 7.69465C10.169 7.2727 9.59674 7.03564 9 7.03564C8.40327 7.03564 7.83097 7.2727 7.40901 7.69465C6.98706 8.11661 6.75 8.68891 6.75 9.28564C6.75 9.88238 6.98706 10.4547 7.40901 10.8766C7.83097 11.2986 8.40327 11.5356 9 11.5356Z"
        fill="#4B5563"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.99225 8.87089C2.10825 5.51764 5.271 3.09814 9.00075 3.09814C12.7282 3.09814 15.8895 5.51539 17.007 8.86564C17.097 9.13714 17.097 9.42965 17.007 9.70039C15.8917 13.0536 12.7282 15.4731 8.99925 15.4731C5.27175 15.4731 2.10975 13.0559 0.993 9.70564C0.902811 9.4347 0.902811 9.14184 0.993 8.87089H0.99225ZM12.9375 9.28564C12.9375 10.3299 12.5227 11.3315 11.7842 12.0699C11.0458 12.8083 10.0443 13.2231 9 13.2231C7.95571 13.2231 6.95419 12.8083 6.21577 12.0699C5.47734 11.3315 5.0625 10.3299 5.0625 9.28564C5.0625 8.24135 5.47734 7.23984 6.21577 6.50141C6.95419 5.76299 7.95571 5.34814 9 5.34814C10.0443 5.34814 11.0458 5.76299 11.7842 6.50141C12.5227 7.23984 12.9375 8.24135 12.9375 9.28564Z"
        fill="#4B5563"
      />
    </svg>
  );
};

export default ShowPasswordIcon;
