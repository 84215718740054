import PeopleImport from "pages/PeopleImport/PeopleImport";
import React from "react";

const ManualSegmentCreator = () => {
  return (
    <>
      <PeopleImport inSegment />
    </>
  );
};

export default ManualSegmentCreator;
