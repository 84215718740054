import SettingsAPIBeta from "pages/Settings/SettingsAPIBeta";
import React from "react";

const APITab = () => {
  return (
    <div className="p-5">
      <SettingsAPIBeta />
    </div>
  );
};

export default APITab;
