import React from "react";

const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M13.125 14.875V17.6875C13.125 18.205 12.705 18.625 12.1875 18.625H4.0625C3.81386 18.625 3.5754 18.5262 3.39959 18.3504C3.22377 18.1746 3.125 17.9361 3.125 17.6875V7.0625C3.125 6.545 3.545 6.125 4.0625 6.125H5.625C6.04381 6.12472 6.46192 6.15928 6.875 6.22834M13.125 14.875H15.9375C16.455 14.875 16.875 14.455 16.875 13.9375V9.875C16.875 6.15834 14.1725 3.07417 10.625 2.47834C10.2119 2.40928 9.79381 2.37472 9.375 2.375H7.8125C7.295 2.375 6.875 2.795 6.875 3.3125V6.22834M13.125 14.875H7.8125C7.56386 14.875 7.3254 14.7762 7.14959 14.6004C6.97377 14.4246 6.875 14.1861 6.875 13.9375V6.22834M16.875 11.75V10.1875C16.875 9.44158 16.5787 8.72621 16.0512 8.19876C15.5238 7.67132 14.8084 7.375 14.0625 7.375H12.8125C12.5639 7.375 12.3254 7.27623 12.1496 7.10041C11.9738 6.9246 11.875 6.68614 11.875 6.4375V5.1875C11.875 4.81816 11.8023 4.45243 11.6609 4.1112C11.5196 3.76998 11.3124 3.45993 11.0512 3.19876C10.7901 2.9376 10.48 2.73043 10.1388 2.58909C9.79757 2.44775 9.43184 2.375 9.0625 2.375H8.125"
        stroke="#4B5563"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
