import React from "react";

const DotsIcon = () => {
  return (
    <svg
      width="16"
      height="3"
      viewBox="0 0 16 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 1.5C2.75 1.69891 2.67098 1.88968 2.53033 2.03033C2.38968 2.17098 2.19891 2.25 2 2.25C1.80109 2.25 1.61032 2.17098 1.46967 2.03033C1.32902 1.88968 1.25 1.69891 1.25 1.5C1.25 1.30109 1.32902 1.11032 1.46967 0.96967C1.61032 0.829018 1.80109 0.75 2 0.75C2.19891 0.75 2.38968 0.829018 2.53033 0.96967C2.67098 1.11032 2.75 1.30109 2.75 1.5ZM8.75 1.5C8.75 1.69891 8.67098 1.88968 8.53033 2.03033C8.38968 2.17098 8.19891 2.25 8 2.25C7.80109 2.25 7.61032 2.17098 7.46967 2.03033C7.32902 1.88968 7.25 1.69891 7.25 1.5C7.25 1.30109 7.32902 1.11032 7.46967 0.96967C7.61032 0.829018 7.80109 0.75 8 0.75C8.19891 0.75 8.38968 0.829018 8.53033 0.96967C8.67098 1.11032 8.75 1.30109 8.75 1.5ZM14.75 1.5C14.75 1.69891 14.671 1.88968 14.5303 2.03033C14.3897 2.17098 14.1989 2.25 14 2.25C13.8011 2.25 13.6103 2.17098 13.4697 2.03033C13.329 1.88968 13.25 1.69891 13.25 1.5C13.25 1.30109 13.329 1.11032 13.4697 0.96967C13.6103 0.829018 13.8011 0.75 14 0.75C14.1989 0.75 14.3897 0.829018 14.5303 0.96967C14.671 1.11032 14.75 1.30109 14.75 1.5Z"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DotsIcon;
