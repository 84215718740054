import React, { ReactNode } from "react";
import { setStepperIndex } from "reducers/flow-builder.reducer";
import { useAppDispatch, useAppSelector } from "store/hooks";

const flowBuilderStepperFixtures: { text: string; icon: ReactNode }[] = [
  {
    text: "Design journey",
    icon: (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3834_6189)">
          <path
            d="M8.5 0C4.08214 0 0.5 3.58214 0.5 8C0.5 12.4179 4.08214 16 8.5 16C12.9179 16 16.5 12.4179 16.5 8C16.5 3.58214 12.9179 0 8.5 0ZM8.5 14.6429C4.83214 14.6429 1.85714 11.6679 1.85714 8C1.85714 4.33214 4.83214 1.35714 8.5 1.35714C12.1679 1.35714 15.1429 4.33214 15.1429 8C15.1429 11.6679 12.1679 14.6429 8.5 14.6429Z"
            fill="currentColor"
          />
          <path
            d="M9.4987 4V11.6354H8.29036V5.47917C8.07161 5.63542 7.8355 5.7691 7.58203 5.88021C7.33203 5.98785 7.04557 6.08333 6.72266 6.16667V5.13542C6.92405 5.06944 7.11849 5 7.30599 4.92708C7.49349 4.85417 7.67752 4.77257 7.85807 4.68229C8.0421 4.59201 8.22613 4.49132 8.41016 4.38021C8.59766 4.2691 8.79036 4.14236 8.98828 4H9.4987Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3834_6189">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    text: "Entry",
    icon: (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3834_7513)">
          <path
            d="M8.5 0C4.08214 0 0.5 3.58214 0.5 8C0.5 12.4179 4.08214 16 8.5 16C12.9179 16 16.5 12.4179 16.5 8C16.5 3.58214 12.9179 0 8.5 0ZM8.5 14.6429C4.83214 14.6429 1.85714 11.6679 1.85714 8C1.85714 4.33214 4.83214 1.35714 8.5 1.35714C12.1679 1.35714 15.1429 4.33214 15.1429 8C15.1429 11.6679 12.1679 14.6429 8.5 14.6429Z"
            fill="currentColor"
          />
          <path
            d="M10.9648 11.5938H6.27734V11.0156C6.27734 10.7795 6.31033 10.559 6.3763 10.3542C6.44227 10.1458 6.53082 9.95139 6.64193 9.77083C6.75304 9.58681 6.88325 9.41493 7.03255 9.25521C7.18186 9.09201 7.34158 8.93576 7.51172 8.78646C7.68186 8.63368 7.85547 8.48785 8.03255 8.34896C8.20964 8.2066 8.38325 8.06424 8.55339 7.92188C8.71311 7.78646 8.85894 7.65451 8.99089 7.52604C9.12283 7.3941 9.23568 7.25868 9.32943 7.11979C9.42318 6.9809 9.49609 6.83507 9.54818 6.68229C9.60026 6.52604 9.6263 6.35764 9.6263 6.17708C9.6263 5.97917 9.59505 5.80729 9.53255 5.66146C9.47352 5.51215 9.38845 5.38889 9.27734 5.29167C9.1697 5.19097 9.04123 5.11632 8.89193 5.06771C8.74262 5.01562 8.57943 4.98958 8.40234 4.98958C8.09679 4.98958 7.78602 5.06076 7.47005 5.20312C7.15408 5.34549 6.85026 5.55903 6.55859 5.84375V4.68229C6.71137 4.56771 6.86589 4.46875 7.02214 4.38542C7.17839 4.29861 7.33984 4.22743 7.50651 4.17188C7.67318 4.11285 7.84852 4.06944 8.03255 4.04167C8.21658 4.01389 8.41276 4 8.62109 4C8.9579 4 9.26345 4.04514 9.53776 4.13542C9.81207 4.22222 10.0464 4.35243 10.2409 4.52604C10.4353 4.69618 10.5846 4.90972 10.6888 5.16667C10.7964 5.42361 10.8503 5.71875 10.8503 6.05208C10.8503 6.35417 10.8121 6.62674 10.7357 6.86979C10.6628 7.10938 10.5569 7.33333 10.418 7.54167C10.2826 7.75 10.1176 7.94792 9.92318 8.13542C9.72873 8.32292 9.51172 8.51562 9.27214 8.71354C9.07075 8.87674 8.86936 9.03646 8.66797 9.19271C8.47005 9.34549 8.29123 9.49653 8.13151 9.64583C7.97179 9.79167 7.84158 9.93924 7.74089 10.0885C7.64366 10.2344 7.59505 10.3819 7.59505 10.5312V10.5573H10.9648V11.5938Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_3834_7513">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    text: "Settings",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_185_4985)">
          <path
            d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z"
            fill="currentColor"
          />
          <path
            d="M5.77783 11.3177V10.1823C6.26394 10.5677 6.81255 10.7604 7.42367 10.7604C7.92019 10.7604 8.31603 10.6476 8.61117 10.4219C8.90978 10.1962 9.05908 9.89062 9.05908 9.50521C9.05908 8.65799 8.41151 8.23438 7.11637 8.23438H6.53825V7.26562H7.09033C8.23964 7.26562 8.81429 6.86632 8.81429 6.06771C8.81429 5.3316 8.36637 4.96354 7.47054 4.96354C6.97054 4.96354 6.50005 5.14236 6.05908 5.5V4.42708C6.56603 4.14236 7.16498 4 7.85596 4C8.51915 4 9.0504 4.16667 9.44971 4.5C9.84901 4.83333 10.0487 5.26042 10.0487 5.78125C10.0487 6.76389 9.54867 7.39583 8.54867 7.67708V7.69792C9.08686 7.75 9.51221 7.93924 9.82471 8.26562C10.1372 8.58854 10.2935 8.99306 10.2935 9.47917C10.2935 10.1493 10.0417 10.691 9.53825 11.1042C9.03478 11.5139 8.37158 11.7188 7.54867 11.7188C6.80908 11.7188 6.2188 11.5851 5.77783 11.3177Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_185_4985">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    text: "Review",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_401_499)">
          <path
            d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z"
            fill="currentColor"
          />
          <path
            d="M5 10.3594V9.5625L8.51562 4H9.09375V5.23438H8.70312L6.04688 9.4375V9.5H10.7812V10.3594H5ZM8.76562 12V10.1172V9.74609V4H9.6875V12H8.76562Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_401_499">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

const FlowBuilderStepper = () => {
  const stepperIndex = useAppSelector(
    (state) => state.flowBuilder.stepperIndex
  );

  const dispatch = useAppDispatch();

  return (
    <div className="flex items-center">
      {flowBuilderStepperFixtures.map((item, i) => (
        <React.Fragment key={i}>
          <div className="px-5">
            <div
              className={`py-[18px] font-inter text-[14px] leading-[24px] font-normal select-none ${
                stepperIndex === i
                  ? "border-b-[4px] border-[#6366F1] text-[#6366F1] !font-semibold"
                  : stepperIndex < i
                  ? "text-[#9CA3AF]"
                  : "text-[#111827] cursor-pointer"
              }`}
              onClick={() => {
                if (i < stepperIndex) dispatch(setStepperIndex(i as 0 | 1 | 2));
              }}
            >
              <div className={`flex gap-[10px] items-center`}>
                <div>{item.icon}</div>
                <div>{item.text}</div>
              </div>
            </div>
          </div>
          {i !== flowBuilderStepperFixtures.length - 1 && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={
                i >= stepperIndex ? "text-[#D1D5DB]" : "text-[#000000D9]"
              }
            >
              <path
                d="M13.0304 7.55042L4.98036 1.26292C4.95932 1.24636 4.93404 1.23607 4.90742 1.23323C4.8808 1.23038 4.85392 1.23511 4.82987 1.24686C4.80581 1.25861 4.78556 1.2769 4.77143 1.29964C4.7573 1.32238 4.74987 1.34865 4.75 1.37542V2.75578C4.75 2.84328 4.79107 2.9272 4.85893 2.98078L11.2875 8.00042L4.85893 13.0201C4.78929 13.0736 4.75 13.1576 4.75 13.2451V14.6254C4.75 14.7451 4.8875 14.8111 4.98036 14.7379L13.0304 8.45042C13.0988 8.39705 13.1541 8.32878 13.1922 8.2508C13.2303 8.17282 13.2501 8.08719 13.2501 8.00042C13.2501 7.91364 13.2303 7.82801 13.1922 7.75004C13.1541 7.67206 13.0988 7.60379 13.0304 7.55042Z"
                fill="currentColor"
              />
            </svg>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FlowBuilderStepper;
