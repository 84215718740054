import React from "react";

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.37 38.194C39.308 36.373 40.8519 34.1738 41.9063 31.7324C42.9607 29.291 43.5031 26.6593 43.5 24C43.5 13.23 34.77 4.5 24 4.5C13.23 4.5 4.50001 13.23 4.50001 24C4.49694 26.6593 5.03935 29.291 6.09371 31.7324C7.14808 34.1738 8.69199 36.373 10.63 38.194C14.2439 41.6078 19.0287 43.5067 24 43.5C28.9713 43.5067 33.7561 41.6078 37.37 38.194ZM12.29 35.624C13.6942 33.8673 15.476 32.4495 17.5033 31.4758C19.5305 30.5022 21.7511 29.9978 24 30C26.2489 29.9978 28.4695 30.5022 30.4967 31.4758C32.524 32.4495 34.3059 33.8673 35.71 35.624C34.1781 37.1714 32.3541 38.3992 30.344 39.2362C28.3338 40.0733 26.1775 40.5028 24 40.5C21.8226 40.5028 19.6662 40.0733 17.6561 39.2362C15.6459 38.3992 13.822 37.1714 12.29 35.624ZM31.5 18C31.5 19.9891 30.7098 21.8968 29.3033 23.3033C27.8968 24.7098 25.9891 25.5 24 25.5C22.0109 25.5 20.1032 24.7098 18.6967 23.3033C17.2902 21.8968 16.5 19.9891 16.5 18C16.5 16.0109 17.2902 14.1032 18.6967 12.6967C20.1032 11.2902 22.0109 10.5 24 10.5C25.9891 10.5 27.8968 11.2902 29.3033 12.6967C30.7098 14.1032 31.5 16.0109 31.5 18Z"
        fill="#4B5563"
      />
    </svg>
  );
};

export default UserIcon;
